<template>
  <div class="project-video">
    <Slider :value="items" :display-thumbnail="true" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Slider from '@kvass/media-render/Slider'

export default {
  computed: {
    ...mapState('Project', ['item']),
    items() {
      return [this.$path('customFields.video', this.item)] || []
    },
  },
  components: {
    Slider,
  },
}
</script>

<style lang="scss">
.project-video {
  width: 100%;
  aspect-ratio: 16/9;
}
</style>
