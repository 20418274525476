<template>
  <div>
    <ImageText v-if="value.length" :value="value" theme="None" class="additional-info" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { GetCustomField } from '@/utils'

import ImageText from '@kvass/template-image-text'

export default {
  computed: {
    ...mapState('Project', ['item']),
    value() {
      let data = GetCustomField('additional-info', this.item.customFields) || []
      return data.map((item, index) => {
        let titleRegexp = /<h2>(.+)<\/h2>/
        let titleMatch = titleRegexp.exec(item.content)

        return {
          title: titleMatch && titleMatch[1],
          description: item.content ? item.content.replace(titleRegexp, '') : null,
          image: item.image ? item.image.url : null,
        }
      })
    },
  },
  components: {
    ImageText,
  },
}
</script>

<style lang="scss">
.additional-info {
  $breakAt: 850px;

  column-gap: 6rem;
  padding: 6rem 0;

  .kvass-image-text {
    &__item {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 6rem;
      align-items: center;

      @media (max-width: $breakAt) {
        grid-template-columns: 1fr;
        grid-gap: 2rem;
      }

      @media (min-width: $breakAt + 1) {
        &:nth-child(even) {
          direction: rtl;

          .kvass-image-text__content {
            text-align: right;
            margin-right: 4rem;
            margin-left: 0;
          }
        }
      }

      * {
        direction: ltr;
        text-align: left;
      }

      img {
        max-width: 100%;
        height: auto;
      }

      & + .kvass-image-text__item {
        margin-top: 12rem;

        @media (max-width: $breakAt) {
          margin-top: 4rem;
        }
      }
    }

    &__content {
      max-width: 400px;
      margin-left: 4rem;

      @media (max-width: $breakAt) {
        max-width: none;
      }

      @media (max-width: 1200px) {
        margin: 0 !important;
      }
    }
  }
}
</style>
