<template>
  <div class="project-process">
    <div v-if="items && items.length" class="project-process__content">
      <div
        v-for="(item, index) in items"
        :key="index"
        class="project-process__item"
        :class="{
          'project-process__item--active': checkActive(index),
          'project-process__item--check': item['check-progress'],
        }"
      >
        <div
          class="project-process__item-icon"
          :style="{ backgroundImage: `url(${item.icon.url})` }"
          :title="item.description"
          v-tooltip="{
            triggers: ['hover', 'click'],
            content: item.description,
            disabled: !isMobile,
          }"
        ></div>
        <div v-if="item['check-progress']" class="project-process__item-check-mark">
          <FontAwesomeIcon :icon="['fas', 'check']" />
        </div>
        <div class="project-process__item-description">
          {{ item.description }}
        </div>
      </div>
    </div>
    <!-- <div class="project-process__brochure">
      <BrochureComponent :value="brochure" class="project-info__brochure" />
    </div> -->
  </div>
</template>

<script>
import { mapState } from 'vuex'

import BrochureComponent from '@/components/Brochure'

export default {
  computed: {
    ...mapState('Project', ['item']),
    brochure() {
      return this.$path('item.media.brochure') || []
    },
    items() {
      return this.$path('item.customFields.progress') || []
    },
    isMobile() {
      return window.innerWidth <= 800
    },
  },
  methods: {
    checkActive(index) {
      if (this.items[index]['check-progress']) return false
      //index = 0 is always active
      if (index === 0) return true
      if (this.items[index - 1]['check-progress']) return true
      return false
    },
  },
  components: {
    BrochureComponent,
  },
}
</script>

<style lang="scss">
.project-process {
  $line: 2px solid var(--primary);
  $lineHeight: 1.3em;
  $iconSize: 65px;

  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-width: 80%;
  margin: 0 auto;

  @include respond-below('phone') {
    gap: 1rem;
  }

  &__brochure {
    display: flex;
    justify-content: center;
    padding-top: 2rem;
  }

  &__content {
    display: flex;
    min-height: 120px;
    justify-content: center;

    @include respond-below('phone') {
      min-height: 70px;
    }
  }

  &__item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 0;

    @include respond-below('phone') {
      padding: 0.2rem;
    }

    //horizontal line
    &::after {
      content: '';
      position: absolute;
      display: block;
      height: $lineHeight;
      bottom: calc(-#{$lineHeight} / 2);
      width: 50%;
      right: 0;
      border-left: $line;
    }

    // vertical lign
    &::before {
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      height: 4px;
      bottom: 0;
      right: 0;
      border-bottom: $line;
      opacity: 1;
    }

    &:first-child {
      // vertical line
      &::before {
        width: 50%;
        right: 0;
        border-bottom: $line;
      }
    }

    &:last-child {
      // vertical line
      &::before {
        width: 50%;
        left: 0;
        border-bottom: $line;
      }
    }

    &-icon {
      width: $iconSize;
      height: $iconSize;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      opacity: 0.5;
      padding: 0.25rem;
      background-origin: content-box;
      @include respond-below('phone') {
        width: calc(#{$iconSize}/ 1.2);
        height: calc(#{$iconSize}/ 1.2);
        font-size: 0.8em;
        svg {
          font-size: 0.8em;
        }
      }
    }

    &-check-mark {
      $checkMarkSize: calc(#{$iconSize}/ 2.8);
      z-index: 1;
      position: absolute;
      top: -5px;
      left: 33%;
      color: white;
      width: $checkMarkSize;
      height: $checkMarkSize;
      background: var(--primary);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.8em;
      opacity: 0.8;
      svg {
        font-size: 1em;
      }

      @include respond-below('phone') {
        top: 0px;
        left: 10px;

        width: 18px;
        height: 18px;
        font-size: 0.8em;
        svg {
          font-size: 0.8em;
        }
      }
    }

    &-description {
      text-align: center;
      opacity: 0.5;
      padding: 0 0.5rem;
      font-weight: bold;
      font-size: 0.9em;
      @include respond-below('phone') {
        display: none;
      }
    }
    &--check,
    &--active {
      .project-process__item-description,
      .project-process__item-icon {
        opacity: 1;
      }
    }
  }
}
</style>
