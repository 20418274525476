<template>
  <div v-if="content" class="custom-info">
    <div class="custom-info__image">
      <img :src="content.image.url" alt="Info bilde" />
    </div>
    <div class="custom-info__description-new-left">
      <span v-html="content.left"></span>
    </div>
    <div class="custom-info__description-new-right">
      <span v-html="content.right"></span>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('Project', ['item']),
    content() {
      return this.$path('item.customFields.info')
    },
  },
}
</script>&

<style lang="scss">
.custom-info {
  width: 100%;
  margin: 0 auto;
  position: relative;
  top: 0%;
  padding-bottom: 4rem;

  &__image {
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
    height: var(--image-size);
    width: var(--image-size);
    position: absolute;
    z-index: 3;
    top: 0%;
    left: 50%;
    transform: translate(-50%, -20%) scale(0.85);
  }

  &__image img {
    height: var(--image-size);
    width: var(--image-size);
    object-fit: cover;
  }

  &__description-new-left {
    padding-top: 5rem;
    width: 45%;
    float: left;
  }

  &__description-new-right {
    padding-top: 5rem;
    width: 45%;
    float: right;
  }

  &__description-new-left::before,
  &__description-new-right::before {
    content: '';
    width: 240px;
    min-height: 430px;
    shape-margin: 1rem;
  }

  &__description-new-left::before {
    float: right;
    shape-outside: polygon(127% -39%, 0% 30%, 127% 100%);
  }

  &__description-new-right::before {
    float: left;
    shape-outside: polygon(-27% -40%, 100% 30%, -27% 100%);
  }

  @media (max-width: 700px) {
    &__description-new-right::before,
    &__description-new-left::before {
      display: none;
    }
    &__description-new-left,
    &__description-new-right {
      width: initial;
      float: none;
      padding-top: 0;
    }
    &__image {
      margin-top: 3rem;
      height: 100%;
      width: 100%;
      position: initial;
      clip-path: none;
      transform: none;
    }
    &__image img {
      margin: 0 auto;
      max-height: 100%;
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }
}
</style>
