<template>
  <Residentials :filter="filter" :project="project" :properties="properties" />
</template>

<script>
import API from '@/api'
import Bus from '@/bus'

import Residentials from '@kvass/residentials/src/Component'

export default {
  data() {
    return {
      filter: {},
      project: API.project,
    }
  },
  computed: {
    properties() {
      return KvassConfig.get('siteSettings.featuredResidentialProperties') || []
    },
  },
  created() {
    Bus.on('residentials:filter', filter => (this.filter = filter))
  },
  components: {
    Residentials,
  },
}
</script>
