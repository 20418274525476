var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"project-process"},[(_vm.items && _vm.items.length)?_c('div',{staticClass:"project-process__content"},_vm._l((_vm.items),function(item,index){return _c('div',{key:index,staticClass:"project-process__item",class:{
        'project-process__item--active': _vm.checkActive(index),
        'project-process__item--check': item['check-progress'],
      }},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          triggers: ['hover', 'click'],
          content: item.description,
          disabled: !_vm.isMobile,
        }),expression:"{\n          triggers: ['hover', 'click'],\n          content: item.description,\n          disabled: !isMobile,\n        }"}],staticClass:"project-process__item-icon",style:({ backgroundImage: ("url(" + (item.icon.url) + ")") }),attrs:{"title":item.description}}),(item['check-progress'])?_c('div',{staticClass:"project-process__item-check-mark"},[_c('FontAwesomeIcon',{attrs:{"icon":['fas', 'check']}})],1):_vm._e(),_c('div',{staticClass:"project-process__item-description"},[_vm._v(" "+_vm._s(item.description)+" ")])])}),0):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }