<template>
  <div class="residential-cover__wrapper content" v-if="items">
    <Slider class="residential-cover" :value="[items]" :display-thumbnail="true" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Slider from '@kvass/media-render/Slider'

export default {
  computed: {
    ...mapState('Residential', ['item']),
    items() {
      return this.$path('item.customFields.video')
    },
  },
  components: {
    Slider,
  },
}
</script>

<style lang="scss">
.residential-cover {
  @include aspect-ratio(16, 9);

  &__wrapper {
    height: 50vh;
    background-color: css-alpha('primary', 0.07);

    @include respond-below('phone') {
      height: initial;
    }
  }
}
</style>
