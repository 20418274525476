<template>
  <Lead
    class="residential-lead"
    :project="$path('project.id', item)"
    :reference="{ onModel: 'Residential', ref: item.id }"
  />
</template>

<script>
import { mapState } from 'vuex'
import Lead from '@kvass/lead'

export default {
  computed: {
    ...mapState('Residential', ['item']),
  },
  components: {
    Lead,
  },
}
</script>

