<template>
  <div class="project-hero">
    <div class="project-hero__overlay">
      <div class="project-hero__overlay-content">
        <slot name="overlay" />
      </div>
    </div>
    <div class="project-hero__slide">
      <Slider
        v-if="items.length"
        :value="items"
        :display-thumbnail="true"
        :manual-navigation="true"
      />
    </div>

    <div class="project-hero__content">
      <div class="project-hero__stats">
        <div v-if="numberOfPropertyTypes > 0" class="project-hero__stats-item">
          <div class="project-hero__stats-label">Enhetstyper</div>
          <div class="project-hero__stats-value">{{ numberOfPropertyTypes }}</div>
        </div>
        <div class="project-hero__stats-item" v-if="priceFrom">
          <div class="project-hero__stats-label">{{ $tc('priceFrom') }}</div>
          <div class="project-hero__stats-value">{{ priceFrom }}</div>
        </div>
        <template v-for="({ key, type, label }, index) in enabledStatus">
          <div :key="index" class="project-hero__stats-item">
            <div class="project-hero__stats-label">
              {{ $t(label || `residentialStatus:${type}.${key}`) }}
            </div>
            <div class="project-hero__stats-value">{{ getStat(key) }}</div>
          </div>
        </template>
        <div
          v-if="item.id && !getStat('total')"
          class="project-hero__stats-item project-hero__stats-item--coming"
        >
          <div class="project-hero__stats-value">{{ upcomingLabel }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { ScrollIntoView } from '@/utils'
import Slider from '@kvass/media-render/Slider'

export default {
  computed: {
    ...mapState('Project', ['item']),
    items() {
      return this.$path('item.media.cover') || []
    },
    upcomingLabel() {
      return KvassConfig.get('customLabels.upcoming') || this.$t('upcomingLabel')
    },
    enabledStatus() {
      return [
        { key: 'sale', type: 'sale' },
        { key: 'sold', type: 'sale' },
        { key: 'rent', type: 'rent' },
        { key: 'rented', type: 'rent' },
        { key: 'reserved', type: 'sale' },
        { key: 'upcoming', type: 'sale' },
      ].filter(e => this.getStat(e.key))
    },
    priceFrom() {
      if (this.item.metadata)
        return this.item.metadata.residentialProperties.price[0]?.toLocaleString('no-NB', {
          style: 'currency',
          currency: 'NOK',
          maximumFractionDigits: 0,
        })
    },
    numberOfPropertyTypes() {
      return this.$path('item.metadata.residentialProperties.propertyType.length')
    },
  },
  methods: {
    gotoInfo() {
      ScrollIntoView('.section__info', -document.querySelector('.nav').clientHeight)
    },
    goToFlatfinder() {
      let target = document.querySelector('#flatfinder')
      if (!target) return
      target.scrollIntoView({ behavior: 'smooth' })
    },
    getStat(key) {
      let keys = key instanceof Array ? key : [key]

      return keys.reduce((r, c) => r + KvassConfig.get(`stats.${c}`) || 0, 0)
    },
  },
  metaInfo() {
    return {
      link: [...this.items.map(item => ({ rel: 'preload', as: 'image', href: item }))],
    }
  },
  components: {
    Slider,
  },
}
</script>

<style lang="scss">
.project-hero {
  $height: 100vh;

  position: relative;
  height: calc(100vh - 80px);
  width: 100%;
  background-color: css-alpha('primary', 0.1);

  display: flex;
  flex-direction: column;

  &__button {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 35%;
    margin-left: auto;
    margin-right: auto;
    min-width: 200px;
    font-size: 1.25rem;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 500ms;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  &__content {
    position: absolute;
    bottom: 0rem;
    display: flex;
    padding: 0;
    justify-content: center;
    align-items: center;
    z-index: 1;
    // overflow: hidden;
    width: 100%;
    margin-bottom: -40px;

    @include respond-below('phone') {
      position: static;
      margin-bottom: 0;
    }
  }

  &__stats {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    @include respond-below('phone') {
      flex-direction: row !important;
      width: 100%;

      &-item {
        width: auto;
        border-radius: 0 !important;
        flex-grow: 1;
        color: var(--primary-contrast);

        @for $n from 2 to 5 {
          &:nth-child(#{$n}) {
            background-color: hsl(
              var(--primary-h),
              var(--primary-s),
              calc(var(--primary-l) + #{$n + 1.5+ '%'})
            );
          }
        }
      }
    }

    @include respond-below('tablet') {
      flex-direction: column;
    }

    &-value {
      font-size: 1.3rem;
      font-weight: bold;
    }

    &-label {
      opacity: 0.6;
      margin-bottom: 0.8rem;

      @include respond-below('phone') {
        font-size: 0.8rem;
        margin-top: 0.8rem;
      }
    }

    &-item:first-child {
      border-radius: 3px 0px 0px 3px;
    }

    &-item:last-child {
      border-radius: 0 3px 3px 0;
    }

    &-item {
      background-color: var(--primary);
      box-shadow: 0 10px 15px -10px rgba(black, 0.05);
      color: var(--primary-contrast);
      line-height: 1;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 1.5rem 0.5rem;
      width: 180px;

      @include respond-below('tablet') {
        width: 110px;
        height: auto;
        padding: 1rem 0.5rem;
      }

      &--coming {
        width: initial;
        padding: 1.5rem;
        font-size: 0.7em;

        & > * {
          margin: 0;
        }
      }
    }
  }

  &__slide {
    position: relative;
    flex-grow: 1;
    width: 100%;
    height: 100%;
    iframe {
      min-height: $height;
    }
  }

  &__arrow {
    position: absolute;
    left: 50%;
    bottom: 2rem;
    padding: 2rem;
    z-index: 1;
    border-radius: 50%;
    color: white;
    background: radial-gradient(black 0%, transparent 50%);
    transition: opacity 150ms ease-out;
    transform: translateX(-50%);

    #app.page--is-scrolled & {
      opacity: 0;
    }

    svg {
      $movement: 5px;
      @keyframes point {
        0% {
          transform: translateY(-$movement);
        }
        50% {
          transform: translateY($movement);
        }
        100% {
          transform: translateY(-$movement);
        }
      }

      animation: point 2s infinite;
    }
  }
}
</style>
