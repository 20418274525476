<template>
  <Loader :value="promises.fetch" theme="default">
    <div class="project-page">
      <Hero class="section__hero">
        <template #overlay>
          <div
            class="row center middle"
            style="position: absolute; inset: 0; z-index: 1"
            v-if="showFlatfinder && $path('customFields.flatfinder-button', item)"
          >
            <ButtonComponent
              @click="goToFlatfinder"
              :label="$path('customFields.flatfinder-button', item)"
              theme="primary"
              class="section__hero-action"
            />
          </div>
        </template>
      </Hero>
      <section class="section section__progress" v-if="$path('customFields.progress', item)">
        <ProgressBar />
      </section>
      <section class="section section__info">
        <div class="container">
          <div class="grow-1">
            <Openhouse v-if="showOpenhouse" class="mb-6" />
            <Roles />
          </div>
          <Info class="grow-1" />
        </div>
      </section>
      <section class="section section__lead">
        <ScrollAnchor value="lead" offset="-8rem" :delay="750" />
        <div class="container">
          <Lead />
        </div>
      </section>
      <section class="section__custom-info" v-if="$path('customFields.info', item)">
        <div class="container">
          <CustomInfo />
        </div>
      </section>
      <section class="section__video" v-if="$path('customFields.video', item)">
        <div class="container">
          <Video />
        </div>
      </section>
      <section class="section__additional-info">
        <div class="container">
          <ScrollAnchor value="additional-info" offset="-8rem" :delay="750" />
          <AdditionalInfo />
        </div>
      </section>
      <section v-if="showFlatfinder" class="section section__flatfinder">
        <ScrollAnchor value="flatfinder" offset="-8rem" :delay="750" />
        <Flatfinder />
      </section>
      <section v-if="showResidentials" class="section section__residentials">
        <div class="container">
          <ScrollAnchor value="residentials" offset="-8rem" :delay="750" />
          <Residentials />
        </div>
      </section>
      <section v-if="showResidentialTemplates" class="section section__residential-templates">
        <div class="container">
          <ScrollAnchor value="residential-templates" offset="-8rem" :delay="750" />
          <ResidentialTemplates />
        </div>
      </section>
      <section class="section section__attachments" v-if="$path('media.attachments.length', item)">
        <div class="container">
          <ScrollAnchor value="attachments" offset="-8rem" :delay="750" />
          <Attachments />
        </div>
      </section>
      <section
        v-if="$path('media.gallery.length', item)"
        class="section section__images section--no-p"
      >
        <ScrollAnchor value="gallery" offset="-8rem" :delay="750" />
        <Masonry />
      </section>
      <section class="section section__map section--no-p">
        <ScrollAnchor value="map" offset="-8rem" :delay="750" />
        <Map />
      </section>
    </div>
  </Loader>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ProjectComponents from '@/components/Project'
import Residentials from '@/components/Residentials'
import ScrollAnchor from '@kvass/scroll-anchor'
import BrowserApiMixin from '@/mixins/browser-api'
import { GetCustomField, ScrollIntoView } from '@/utils'

export default {
  mixins: [BrowserApiMixin('project')],
  computed: {
    ...mapState('Project', ['promises', 'item']),
    showFlatfinder() {
      return this.$path('item.flatfinders.length') && KvassConfig.get('stats.total')
    },
    showOpenhouse() {
      if (!this.item.id) return false
      if (this.$path('item.openhouses.length')) return true
      return !KvassConfig.get('siteSettings.hideNoOpenhouse')
    },
    showResidentialTemplates() {
      if (!this.item) return false
      return GetCustomField('residential-templates', this.item.customFields)
    },
    showResidentials() {
      return KvassConfig.get('stats.total') > 0
    },
    showProgress() {
      if (this.$path('item.customFields.progress')) return true
    },
  },
  methods: {
    ...mapActions('Project', ['fetch']),
    GetCustomField,
    goToFlatfinder() {
      ScrollIntoView('.flatfinder', -document.querySelector('.nav').clientHeight)
    },
  },
  created() {
    this.fetch()
  },
  metaInfo() {
    return {
      title: this.$t('home'),
    }
  },
  components: {
    ...ProjectComponents,
    Residentials,
    ScrollAnchor,
  },
}
</script>

<style lang="scss">
.project-page {
  @for $i from 1 through 15 {
    & > *:nth-child(#{$i}) {
      order: $i * 10;
    }
  }
  display: flex;
  flex-direction: column;

  .section {
    &--no-pb {
      padding-bottom: 0;
    }

    &--no-p {
      padding-top: 0;
      padding-bottom: 0;
    }

    &__shop {
      min-height: 100vh;
    }

    &__lead {
      // background-color: hsla(var(--primary-h), var(--primary-s), var(--primary-l), 0.05);
      position: relative;
      padding-top: 4rem;
      padding-bottom: 4rem;

      .project-lead {
        background-color: transparent;
        max-width: initial;
      }
    }

    &__info {
      .container {
        display: grid;
        grid-gap: 6rem;
        grid-template-columns: repeat(2, 1fr);

        @include respond-below('phone') {
          grid-template-columns: 1fr;

          & > *:first-child {
            order: 2;
          }

          & > *:nth-child(2) {
            order: 1;
          }
        }
      }
    }

    &__custom-info {
      background-color: hsla(var(--primary-h), var(--primary-s), var(--primary-l), 0.05);
      margin-top: 4rem;
      padding-bottom: 4rem;
    }

    &__progress {
      background-color: hsla(var(--primary-h), var(--primary-s), var(--primary-l), 0.05);
    }

    &__hero {
      &-action {
        font-size: 1.2rem;
        background-color: hsla(var(--primary-h), var(--primary-s), var(--primary-l), 0.5);
        backdrop-filter: blur(4px);
        border-color: transparent;

        .elder-button__label {
          padding: 0.5rem 2.5rem;
        }
      }
    }
  }
}
</style>
